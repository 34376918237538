import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {AuthorizedComponent} from './authorized/authorized.component';
import {PageNotFoundComponent} from './page-not-found/page-not-found.component';
import {ServerErrorComponent} from './server-error/server-error.component';
import {AuthComponent} from './auth.component';
import {GoogleAuthComponent} from './google-auth/google-auth.component';
import { RegistrationComponent } from './registration/registration.component';
import {LoginAdminComponent} from './login-admin/login-admin.component';
import {SuccessVerifyComponent} from './success-verify/success-verify.component';
import {SuccessRegisterComponent} from './success-register/success-register.component';
import { LoginComponent } from './login/login.component';


const routes: Routes = [
  {
    path: '',
    component: AuthComponent,
    children: [
      /*{
        path: '',
        redirectTo: 'login',
        pathMatch: 'full'
      },*/
      {
        path: 'signup-success/:name/:email', component: SuccessRegisterComponent
      },
      {
        path: 'verify/:code', component: SuccessVerifyComponent
      },
      {
        path: 'admin-login',
        component: LoginAdminComponent,
      },
      {
        path: 'login',
        component: LoginComponent,
      },
      {
        path: 'google/:token',
        component: GoogleAuthComponent,
      },
      {
        path: 'registration',
        component: RegistrationComponent
      },
      {path: '', redirectTo: 'login', pathMatch: 'full'},
      { path: '**', component: PageNotFoundComponent },
    ]
  }
];
  /*[
  { path: 'login', component: LoginComponent },
  { path: 'unauthorized', component: AuthorizedComponent },
  { path: '**', component: PageNotFoundComponent },
  { path: '**', redirectTo: '/404' }
]*/

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule {}
