
import { NgModule} from '@angular/core';
import { IconDefinition } from '@ant-design/icons-angular';
import * as AllIcons from '@ant-design/icons-angular/icons';
import {AdminLayoutComponent} from './admin-layout.component';
import {SharedModule} from '../../shared/shared.module';
import {registerLocaleData} from '@angular/common';
import en from '@angular/common/locales/en';
registerLocaleData(en);
import {RouterModule} from '@angular/router';
import {AdminLayoutRoutingModule} from './admin-layout-routing.module';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzBreadCrumbModule } from 'ng-zorro-antd/breadcrumb';
import {MenuService} from '../../service/menu.service';
import {en_US, NZ_I18N} from 'ng-zorro-antd/i18n';
import {NZ_ICONS} from 'ng-zorro-antd/icon';
import { AsideComponent } from './aside/aside.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { HeaderMobileComponent } from './header-mobile/header-mobile.component';
import { SamplePageComponent } from './pages/sample-page/sample-page.component';
import { ScriptsInitComponent } from './init/scripts-init/scripts-init.component';
import {NgbDropdownModule, NgbProgressbarModule} from '@ng-bootstrap/ng-bootstrap';
import { TopbarComponent } from './topbar/topbar.component';
import {CRUDTableModule} from './shared/crud-table';
import {ControlModule} from './controls/control.module';
import { AdminMasterComponent } from './master-page/admin-master/admin-master.component';
import { PortalMasterComponent } from './master-page/portal-master/portal-master.component';
import { CscMasterComponent } from './master-page/csc-master/csc-master.component';
import { LanguageSelectorComponent } from './topbar/language-selector/language-selector.component';
import { NotificationsDropdownInnerComponent } from './topbar/notifications-dropdown-inner/notifications-dropdown-inner.component';
import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import {DashboardModule} from './pages/dashboard/modules/dashboard.module';

const antDesignIcons = AllIcons as {
  [key: string]: IconDefinition;
};
const icons: IconDefinition[] = Object.keys(antDesignIcons).map(key => antDesignIcons[key]);
@NgModule({
  declarations: [
    AdminLayoutComponent,
    AsideComponent,
    FooterComponent,
    HeaderComponent,
    HeaderMobileComponent,
    SamplePageComponent,
    ScriptsInitComponent,
    TopbarComponent,
    AdminMasterComponent,
    PortalMasterComponent,
    CscMasterComponent,
    LanguageSelectorComponent,
    NotificationsDropdownInnerComponent,
    DashboardComponent,
  ],
  imports: [
    ControlModule,
    SharedModule,
    RouterModule.forChild([]),
    AdminLayoutRoutingModule,
    NzDropDownModule,
    NgbDropdownModule,
    NzBreadCrumbModule,
    NgbProgressbarModule,
    CRUDTableModule,
    PerfectScrollbarModule,
    DashboardModule
  ],
  providers: [{ provide: NZ_I18N, useValue: en_US }, { provide: NZ_ICONS, useValue: icons }, MenuService]
})
export class LayoutModule {

}
