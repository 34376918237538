import { NgModule} from '@angular/core';
import {SharedModule} from '../../shared/shared.module';
import {AuthRoutingModule} from './auth-routing.module';
import { AuthorizedComponent } from './authorized/authorized.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ServerErrorComponent } from './server-error/server-error.component';
import {AuthService} from '../../service/auth.service';
import {StoreModule} from '@ngrx/store';
import {authReducer} from '../auth/redux/auth.reducer';
import {EffectsModule} from '@ngrx/effects';
import {AuthEffects} from './redux/auth.effects';
import {clearState} from '../admin-layout/admin-layout.component';
import {GeneralService} from '../../service/general-service';
import {UserService} from '../../service/user-service';
import {AuthComponent} from './auth.component';
import { GoogleAuthComponent } from './google-auth/google-auth.component';
import { RegistrationComponent } from './registration/registration.component';
import { LoginAdminComponent } from './login-admin/login-admin.component';
import { SuccessVerifyComponent } from './success-verify/success-verify.component';
import { SuccessRegisterComponent } from './success-register/success-register.component';
import { LoginComponent } from './login/login.component';
@NgModule({
  declarations: [
    LoginComponent,
    RegistrationComponent,
    AuthorizedComponent,
    PageNotFoundComponent,
    ServerErrorComponent,
    AuthComponent,
    GoogleAuthComponent,
    LoginAdminComponent,
    SuccessVerifyComponent,
    SuccessRegisterComponent
  ],
  imports: [
    AuthRoutingModule,
    SharedModule,
    StoreModule.forFeature(
      'auth', authReducer,{ metaReducers: [clearState] }
    ), EffectsModule.forFeature([AuthEffects]),

],
  providers: [AuthService, GeneralService, UserService]

})
export class AuthModule {

}
