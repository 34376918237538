<div class="card card-custom" [ngClass]="cssClass">
  <!--begin::Chart-->
  <div
    id="kt_stats_widget_11_chart"
    class="card-rounded-bottom"
    [style.height]="'150px'"
  >
    <apx-chart
      [series]="chartOptions.series"
      [chart]="chartOptions.chart"
      [dataLabels]="chartOptions.dataLabels"
      [yaxis]="chartOptions.yaxis"
      [xaxis]="chartOptions.xaxis"
      [stroke]="chartOptions.stroke"
      [grid]="chartOptions.grid"
    ></apx-chart>
  </div>
  <!--end::Chart-->
</div>
