<div class="d-flex flex-column flex-root h-100" id="kt_login_wrapper">
  <!--begin::Login-->
  <div class="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid" id="kt_login">
    <!--begin::Aside-->
    <div class="login-aside d-flex flex-column flex-row-auto">
      <!--begin::Aside Top-->
      <div class="d-flex flex-column-auto flex-column pt-lg-40 pt-15">
        <!--begin::Aside header-->
        <a href="#" class="text-center mb-10">
          <img src="./assets/image/login.png" style="max-width: 300px;margin-left: 30px" alt="BỆNH VIỆN ĐA KHOA HÙNG VƯƠNG" />
        </a>
        <!--end::Aside header-->

        <!--begin::Aside title-->
        <h2 class="font-weight-bolder text-center font-size-h4 font-size-h1-lg" style="color: #3156A3;margin-left: 30px">
          BỆNH VIỆN ĐA KHOA HÙNG VƯƠNG
        </h2>
        <h3 class="font-weight-bolder text-center font-size-h4 font-size-h1-lg" style="color: #04b0f0;margin-left: 30px">
          Thân thiện - Chuyên nghiệp - Chu đáo <br/>
        </h3>
        <!--end::Aside title-->
      </div>
      <!--end::Aside Top-->

      <!--begin::Aside Bottom-->
      <!--<div class="aside-img d-flex flex-row-fluid bgi-no-repeat bgi-position-y-bottom bgi-position-x-center"
        style="background-image: url('./assets/image/kg2.png');background-size: contain"></div>-->
      <!--end::Aside Bottom-->
    </div>
    <!--begin::Aside-->

    <!--begin::Content-->
    <div style="background: rgb(255 255 255 / 0.8);"
      class="login-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto">
      <!--begin::Content body-->
      <div class="d-flex flex-column-fluid flex-center">
        <router-outlet></router-outlet>
      </div>
      <!--end::Content body-->

      <!--begin::Content footer-->
      <div class="d-flex justify-content-lg-start justify-content-center align-items-end py-7 py-lg-0">
        <a href="#" class="text-primary font-weight-bolder font-size-h5">Terms</a>
        <a href="#" class="text-primary ml-10 font-weight-bolder font-size-h5">Plans</a>
        <a href="#" class="text-primary ml-10 font-weight-bolder font-size-h5">Contact Us</a>
      </div>
      <!--end::Content footer-->
    </div>
    <!--end::Content-->
  </div>
  <!--end::Login-->
</div>
