import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';

import { Router, NavigationEnd, ActivatedRoute, NavigationStart} from '@angular/router';
import {Constant} from '../../../../shared/constants/constant.class';
import * as fromAuth from '../../../../component/auth/redux/auth.reducer';
import * as actionAuth from '../../../../component/auth/redux/auth.action';
import {ActionsSubject, select, Store} from '@ngrx/store';
import {filter, map} from 'rxjs/operators';
import {Subscription} from 'rxjs';
import {MenuService} from '../../../../service/menu.service';
import {Menu} from '../../../../model/menu.class';
import {AuthService} from '../../../../service/auth.service';
import {TranslateService} from '@ngx-translate/core';
import {LayoutService} from '../../../../service/core/layout.service';
import {LayoutInitService} from '../../../../service/core/layout-init.service';
import KTLayoutContent from '../../../../../assets/js/layout/base/content';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-admin-master',
  templateUrl: './admin-master.component.html',
  styleUrls: ['./admin-master.component.scss']
})
export class AdminMasterComponent implements OnInit , OnDestroy, AfterViewInit {
  static readonly ROUTE_DATA_PAGENAME = 'pagename';
  layoutType = 'ADMIN';
  isCollapsed = false;
  username: string;
  sub: Subscription;
  menus: Menu[] = [];
  groupMenu: any[] = [];
  groupName: any[] = [];
  group: any[] = [];
  selectedMenu: Menu;
  pageName: string;

  //Metronic
  selfLayout = 'default';
  asideSelfDisplay: true;
  contentClasses = '';
  contentContainerClasses = '';
  subheaderDisplay = true;
  contentExtended: false;
  asideCSSClasses: string;
  asideHTMLAttributes: any = {};
  headerMobileClasses = '';
  headerMobileAttributes = {};
  footerDisplay: boolean;
  footerCSSClasses: string;
  headerCSSClasses: string;
  headerHTMLAttributes: any = {};
  // offcanvases
  extrasSearchOffcanvasDisplay = false;
  extrasNotificationsOffcanvasDisplay = false;
  extrasQuickActionsOffcanvasDisplay = false;
  extrasCartOffcanvasDisplay = false;
  extrasUserOffcanvasDisplay = false;
  extrasQuickPanelDisplay = false;
  extrasScrollTopDisplay = false;
  @ViewChild('ktAside', { static: true }) ktAside: ElementRef;
  @ViewChild('ktHeaderMobile', { static: true }) ktHeaderMobile: ElementRef;
  @ViewChild('ktHeader', { static: true }) ktHeader: ElementRef;

  constructor(
    private initService: LayoutInitService,
    private layout: LayoutService,
    private route: Router,
    private activeRoute: ActivatedRoute,
    private store: Store<fromAuth.AppState>,
    private actionsSubject$: ActionsSubject,
    private menuService: MenuService,
    private authService: AuthService,
    private translate: TranslateService,
    private titleService: Title
  ) {
    this.initService.init();
  }
  ngOnDestroy(): void {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }
  ngOnInit(): void {
    const userInfo = JSON.parse(localStorage.getItem(Constant.USER_INFO));
    this.username = userInfo.username;
    this.menus = userInfo.menus;
    if (this.menus)
      this.getGroupMenu();
    this.route.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this.getPageInfo())).subscribe((pageName: string) => {
    });
    this.titleService.setTitle('HID');

    /*this.authService.checkTokenAdmin().subscribe(res => {
      if (res.ret && res.ret[0].code === 401) {
        localStorage.removeItem(Constant.TOKEN);
        localStorage.removeItem(Constant.USER_INFO);
        this.route.navigate(['/admin-login']);
      }
    });*/
    this.metronicInit();
  }
  getMenuByUser() {
    this.menuService.getMenuByUser().subscribe(res => {
      this.menus = res;
    });
  }
  getGroupMenu() {
    for (let index = 0; index < this.menus.length; index++) {
      if (index > 0 && this.menus[index].path === '') {
        this.groupMenu.push(this.group);
        this.group = [];
      }
      if (this.menus[index].path === '') {
        this.groupName.push(this.menus[index].name);
      }
      else {
        this.group.push(this.menus[index]);
      }
    }
    this.groupMenu.push(this.group);
    // return this.groupMenu, this.groupName;
  }

  private getPageInfo() {
    let child = this.activeRoute.firstChild;
    while (child.firstChild) {
      child = child.firstChild;
    }
    if (child.snapshot.data[AdminMasterComponent.ROUTE_DATA_PAGENAME]) {
      return child.snapshot.data[AdminMasterComponent.ROUTE_DATA_PAGENAME];
    }
    return '';
  }

  metronicInit() {
    this.selfLayout = this.layout.getProp('self.layout');
    this.asideSelfDisplay = this.layout.getProp('aside.self.display');
    this.subheaderDisplay = this.layout.getProp('subheader.display');
    this.contentClasses = this.layout.getStringCSSClasses('content');
    this.contentContainerClasses = this.layout.getStringCSSClasses(
      'content_container'
    );
    this.contentExtended = this.layout.getProp('content.extended');
    this.asideHTMLAttributes = this.layout.getHTMLAttributes('aside');

    this.asideCSSClasses = this.layout.getStringCSSClasses('aside');
    this.headerMobileClasses = this.layout.getStringCSSClasses('header_mobile');
    this.headerMobileAttributes = this.layout.getHTMLAttributes(
      'header_mobile'
    );
    this.footerDisplay = this.layout.getProp('footer.display');
    this.footerCSSClasses = this.layout.getStringCSSClasses('footer');
    this.headerCSSClasses = this.layout.getStringCSSClasses('header');
    this.headerHTMLAttributes = this.layout.getHTMLAttributes('header');
    // offcanvases
    if (this.layout.getProp('extras.search.display')) {
      this.extrasSearchOffcanvasDisplay =
        this.layout.getProp('extras.search.layout') === 'offcanvas';
    }

    if (this.layout.getProp('extras.notifications.display')) {
      this.extrasNotificationsOffcanvasDisplay =
        this.layout.getProp('extras.notifications.layout') === 'offcanvas';
    }

    if (this.layout.getProp('extras.quickActions.display')) {
      this.extrasQuickActionsOffcanvasDisplay =
        this.layout.getProp('extras.quickActions.layout') === 'offcanvas';
    }

    if (this.layout.getProp('extras.cart.display')) {
      this.extrasCartOffcanvasDisplay =
        this.layout.getProp('extras.cart.layout') === 'offcanvas';
    }

    if (this.layout.getProp('extras.user.display')) {
      this.extrasUserOffcanvasDisplay =
        this.layout.getProp('extras.user.layout') === 'offcanvas';
    }

    this.extrasQuickPanelDisplay = this.layout.getProp(
      'extras.quickPanel.display'
    );

    this.extrasScrollTopDisplay = this.layout.getProp(
      'extras.scrolltop.display'
    );
  }
  ngAfterViewInit(): void {
    if (this.ktAside) {
      for (const key in this.asideHTMLAttributes) {
        if (this.asideHTMLAttributes.hasOwnProperty(key)) {
          this.ktAside.nativeElement.attributes[key] = this.asideHTMLAttributes[
            key
            ];
        }
      }
    }

    if (this.ktHeaderMobile) {
      for (const key in this.headerMobileAttributes) {
        if (this.headerMobileAttributes.hasOwnProperty(key)) {
          this.ktHeaderMobile.nativeElement.attributes[
            key
            ] = this.headerMobileAttributes[key];
        }
      }
    }

    if (this.ktHeader) {
      for (const key in this.headerHTMLAttributes) {
        if (this.headerHTMLAttributes.hasOwnProperty(key)) {
          this.ktHeader.nativeElement.attributes[
            key
            ] = this.headerHTMLAttributes[key];
        }
      }
    }
    // Init Content
    KTLayoutContent.init('kt_content');
  }
}
export function clearState(reducer) {
  return (state, action) => {
    if (action.type === actionAuth.AuthActionTypes.Logout) {
      state = undefined;
    }
    return reducer(state, action);
  };
}
