<div class="row text-center">
  <div class="col-sm-12">
    <br><br> <h2 style="color:#0fad00">Success</h2>
    <img width="60px" src="./assets/image/check_true.png">
    <h3>Dear, {{name}}</h3>
    <p style="font-size:20px;color:#5C5C5C;">
      Thank you for sign up account.
      <br>
      We have sent you an email <strong>"{{email}}"</strong> with link verify.
      Please go to your email and verify account. Thanks you</p>
    <a href="" class="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-3">Log in</a>
    <br><br>
  </div>
</div>
