import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {InlineSVGModule} from 'ng-inline-svg';
import {NgApexchartsModule} from 'ng-apexcharts';
import {RouterModule} from '@angular/router';
import {SharedModule} from '../../../../../shared/shared.module';
import {CRUDTableModule} from '../../../shared/crud-table';
import { StatsComponent } from './stats/stats.component';
import { ChartComponent } from './chart/chart.component';
import { ListComponent } from './list/list.component';

@NgModule({
  declarations: [StatsComponent, ChartComponent, ListComponent],
  exports: [
    StatsComponent,
    ChartComponent
  ],
  imports: [
    CommonModule,
    InlineSVGModule,
    NgApexchartsModule,
    CRUDTableModule,
    RouterModule,
    SharedModule
  ]
})
export class DashboardModule { }
