export class  UrlConstant {
  public static readonly LOGIN = '/oauth/token';
  public static readonly LOGOUT = '/login/logout';
  public static readonly VALIDATE = '/login/validate';
  public static readonly VALIDATE_ADMIN = '/login/validateadmin';
  public static readonly VALIDATE_CSC = '/login/validatecsc';

  public static readonly LIST_USER = '/user';
  public static readonly DELETE_USER = '/users/delete/';
  public static readonly ADD_USER = '/users';
  public static readonly DETAIL_USER = '/users';
  public static readonly UPDATE_USER = '/users/update';
  public static readonly LIST_ROLE = '/role';
  public static readonly LIST_GROUP = '/group';
  public static readonly LIST_ACTION = '/action';
  public static readonly LIST_MENU = '/menu';
  public static readonly LIST_STAFF = '/staff';
  public static readonly LIST_WORKROLE = '/workRole';
  public static readonly LIST_ORDER = '/order';

  public static readonly URL_ORDER = '/orders';
  public static readonly URL_COUNTRY = '/diccountries';
  public static readonly URL_DEST_CITY = '/dicdestcities';
  public static readonly URL_DISCOUNT_PROGRAM = '/discountprograms';
  public static readonly URL_INDIVIDUAL_DISCOUNT = '/individualdiscounts';
  public static readonly URL_PROVINCE = '/dicprovinces';
  public static readonly URL_HSCODE = '/dichscodes';
  public static readonly URL_PAYMENT = '/payment';

  /*Dinh duong*/
  public static readonly LIST_PHONG = '/department';
  public static readonly LIST_LOAIMON = '/loaimon';
  public static readonly LIST_TAIKHOAN = '/user';
  public static readonly LIST_CUSTOMER = '/customer';
  public static readonly LIST_VOUCHER = '/voucher';
  public static readonly LIST_PARTNER = '/partner';
  public static readonly LIST_MONAN = '/monan';
  public static readonly UPLOAD = '/file/upload';
  public static readonly LIST_DONHANG = '/donHang';
  public static readonly LIST_NHOMTAIKHOAN = '/group';
  public static readonly LIST_KHO = '/warehouse';
  public static readonly LIST_DMTHIETBI = '/dmdevice';
  public static readonly LIST_THIETBI = '/device';
  public static readonly LIST_DM_HANGHOA = '/dmgoods';
  public static readonly LIST_HANGHOA = '/goods';
  public static readonly LIST_MINETURNS = '/mineturns';
  public static readonly LIST_TURNSSTATUS = '/turnsStatus';
  public static readonly LIST_TONKHO = '/warehouseGoods';

  public static readonly LIST_PHIEU_NHAPKHO = '/warehouseImport';
  public static readonly LIST_PHIEU_XUATKHO = '/warehouseExport';

  public static readonly LIST_MINETURNSSTAFF = '/mineturnsstaffs';
  public static readonly LIST_WAREHOUSE_FIRST_IMPORT = '/warehousefirstimport';

  public static readonly  FILE = '/file';
  public static readonly LIST_CALAMVIEC = '/workshift';
  public static readonly LIST_VAITRO = '/workrole';
  public static readonly LIST_BANNER = '/banners';

  public static readonly LIST_ACCOUNT = '/accounts';

}
