<div class="row">
  <div class="col-lg-6 col-xxl-4">
    <app-dashboard-stats></app-dashboard-stats>
  </div>
  <div class="col-xxl-8 order-2 order-xxl-1">
    <app-chart></app-chart>
  </div>
</div>
<div class="row">
  <div class="col-lg-12">
    <div class="card card-custom card-stretch gutter-b">
      <!-- Header -->
      <div class="card-header border-0 pt-5">
        <h3 class="card-title align-items-start flex-column">
          <span class="card-label font-weight-bolder text-dark">New Booking</span>
        </h3>
        <div class="card-toolbar">
          <ul class="nav nav-pills nav-pills-sm nav-dark-75">
            <li
              class="nav-item"
              (click)="setCurrentTab('Month')"
              [style.cursor]="'pointer'"
            >
              <a
                class="nav-link py-2 px-4"
                [class.active]="currentTab === 'Month'"
                data-toggle="tab"
              >Month</a
              >
            </li>
            <li
              class="nav-item"
              (click)="setCurrentTab('Week')"
              [style.cursor]="'pointer'"
            >
              <a
                class="nav-link py-2 px-4"
                [class.active]="currentTab === 'Week'"
                data-toggle="tab"
              >Week</a
              >
            </li>
            <li
              class="nav-item"
              (click)="setCurrentTab('Day')"
              [style.cursor]="'pointer'"
            >
              <a
                class="nav-link py-2 px-4"
                [class.active]="currentTab === 'Day'"
                data-toggle="tab"
              >Day</a
              >
            </li>
          </ul>
        </div>
      </div>
      <!-- Body -->
      <div class="card-body pt-3 pb-0">
        <!--begin::Table-->
        <div class="table-responsive">
          <table
            class="table table-head-custom table-head-bg table-borderless table-vertical-center"
          >
            <thead>
            <tr class="text-left text-uppercase">
              <th style="min-width: 250px;" class="pl-7">
                <span class="text-dark-75">Mã vụ án</span>
              </th>
              <th style="min-width: 100px;">Tên vụ án</th>
              <th style="min-width: 100px;">Bị can đầu vụ</th>
              <th style="min-width: 100px;">QĐ K.Tố vụ án số
              </th>
              <th style="min-width: 130px;">Ngày QĐ</th>
              <th style="min-width: 80px;">CQ ra QĐ</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let rowItem of datas; let i = index;">
              <td class="pl-0 py-8">
                <div class="d-flex align-items-center">
                  <div class="symbol symbol-50 symbol-light mr-4">
                    <span class="symbol-label">
                      <span
                        [inlineSVG]="'./assets/media/svg/avatars/001-boy.svg'"
                        cacheSVG="true"
                        class="svg-icon h-75 align-self-end"
                      ></span>
                    </span>
                  </div>
                  <div>
                    <a
                      class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg cursor-pointer"
                    >{{rowItem.CASECODE}}</a
                    >
                  </div>
                </div>
              </td>
              <td>
                <span
                  class="text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{rowItem.CASENAME}}
                </span>
              </td>
              <td>
                <span
                  class="text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{rowItem.FIRSTACC_TXT}}
                </span>
              </td>
              <td>
                <span
                  class="text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ rowItem.BEGIN_SETNUM}}
                </span>

              </td>
              <td>
            <span class="text-muted font-weight-bold d-block font-size-sm">
                  {{ rowItem.BEGIN_INDATE | dateFormat:'dd-MM-yyyy'}}
                </span>
              </td>
              <td class="pr-0 text-right">
                <a class="btn btn-light-warning font-weight-bolder font-size-sm cursor-pointer">Detail</a>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <!--end::Table-->
      </div>
    </div>

  </div>
</div>
