import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { Injectable } from "@angular/core";

@Injectable()
export class NgbDateCustomDateAdapter {
    fromModel(value: string): NgbDateStruct
    {
        if (!value)
            return null;
        if(value.length > 10)
            value = value.substr(0, 10);
        let parts=value.split('-');
        return {year:+parts[0],month:+parts[1],day:+parts[2]};
    }
  
    toModel(date: NgbDateStruct): string // from internal model -> your mode
    {
      return date?date.year+"-"+('0'+date.month).slice(-2)
             +"-"+('0'+date.day).slice(-2):null
    }
}