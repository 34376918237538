import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, from, of, EMPTY } from 'rxjs';
import { map, concatMap, finalize } from 'rxjs/operators';

import {environment} from '../../environments/environment';
import {VekoAccount} from '../model/account';
import {UserService} from './user-service';
import {Constant} from '../shared/constants/constant.class';
import {Cookie} from 'ng2-cookies';
import {GeneralService} from './general-service';
import {GoogleLoginProvider, SocialAuthService, SocialUser} from 'angularx-social-login';

const baseUrl = `${environment.apiUrl}/`;

@Injectable({ providedIn: 'root' })
export class AccountService {
  private accountSubject: BehaviorSubject<VekoAccount>;
  public account: Observable<VekoAccount>;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private http: HttpClient,
    private userService: UserService,
    private generalService: GeneralService,
    private socialAuthService: SocialAuthService
  ) {
    this.accountSubject = new BehaviorSubject<VekoAccount>(null);
    this.account = this.accountSubject.asObservable();
  }

  public get accountValue(): VekoAccount {
    return this.accountSubject.value;
  }

  login() {
    // login with facebook then authenticate with the API to get a JWT auth token
    this.facebookLogin()
      .pipe(concatMap(accessToken => this.apiFBAuthenticate(accessToken)))
      .subscribe(resData => {
        const returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
        this.doLogin(resData, returnUrl);
        return resData;
      });
  }

  facebookLogin() {
    // login with facebook and return observable with fb access token on success
    return from(new Promise<fb.StatusResponse>(resolve => FB.login(resolve)))
      .pipe(concatMap(({ authResponse }) => {
        //console.log(authResponse);
        if (!authResponse) return EMPTY;
        return of(authResponse.accessToken);
      }));
  }
  googleLogin() {
    this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID).then(authResponse => {
      //console.log(authResponse);
      let accessToken = authResponse.idToken;
      location.href = '/google/' + accessToken;
    });
  }

  apiFBAuthenticate(accessToken: string) {
      return this.http.post<any>(`${baseUrl}Login/Facebook`, { token: accessToken })
        .pipe(map(account => {
          this.accountSubject.next(account);
          this.startAuthenticateTimer();
          return account;
        }));
  }
  apiGoogleAuthenticate(accessToken: string) {
    this.http.post<any>(`${baseUrl}Login/Google`, { token: accessToken })
      .pipe(map(account => {
        this.accountSubject.next(account);
        this.startAuthenticateTimer();
        const returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
        this.doLogin(account, returnUrl);
        //return account;
      }));
  }
  doLogin(account, returnUrl){
    var expiresIn = 499;
    localStorage.setItem(Constant.TOKEN, account.token);
    /*account.username = 'Admin';
    account.fullname = 'Quản trị hệ thống';*/
    localStorage.setItem(Constant.USER_INFO, JSON.stringify(account));
    const expireDate = new Date().getTime() + expiresIn;
    //console.log(expireDate);
    Cookie.set(Constant.TOKEN, account.token, expireDate);

    this.generalService.getInitialData().subscribe(resData => {
      if (resData !== null) {
        localStorage.setItem(Constant.INIT_DATA, JSON.stringify(resData));
      }
    }, error => {

    });
    this.router.navigateByUrl(returnUrl);
  }
  logout() {
    // revoke app permissions to logout completely because FB.logout() doesn't remove FB cookie
    FB.api('/me/permissions', 'delete', null, () => FB.logout());
    this.stopAuthenticateTimer();
    this.accountSubject.next(null);
    this.router.navigate(['/login']);
  }

  getAll() {
    return this.http.get<Account[]>(baseUrl);
  }

  getById(id) {
    return this.http.get<Account>(`${baseUrl}/${id}`);
  }

  update(id, params) {
    return this.http.put(`${baseUrl}/${id}`, params)
      .pipe(map((account: any) => {
        // update the current account if it was updated
        if (account.id === this.accountValue.id) {
          // publish updated account to subscribers
          account = { ...this.accountValue, ...account };
          this.accountSubject.next(account);
        }
        return account;
      }));
  }

  delete(id: string) {
    return this.http.delete(`${baseUrl}/${id}`)
      .pipe(finalize(() => {
        // auto logout if the logged in account was deleted
        if (id === this.accountValue.id)
          this.logout();
      }));
  }

  // helper methods

  private authenticateTimeout;

  private startAuthenticateTimer() {
    // parse json object from base64 encoded jwt token
    this.accountValue
    const jwtToken = JSON.parse(atob(this.accountValue.token.split('.')[1]));

    // set a timeout to re-authenticate with the api one minute before the token expires
    const expires = new Date(jwtToken.exp * 1000);
    const timeout = expires.getTime() - Date.now() - (60 * 1000);
    const { accessToken } = FB.getAuthResponse();
    this.authenticateTimeout = setTimeout(() => {
      this.apiFBAuthenticate(accessToken).subscribe();
    }, timeout);
  }

  private stopAuthenticateTimer() {
    // cancel timer for re-authenticating with the api
    clearTimeout(this.authenticateTimeout);
  }
}
