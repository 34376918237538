import { NgModule} from '@angular/core';
import {StoreModule} from '@ngrx/store';
import {NoticeComponent} from './notice/notice.component';
import {SplashScreenComponent} from './splash-screen/splash-screen.component';
import {SubheaderWrapperComponent} from './subheader/subheader-wrapper/subheader-wrapper.component';
import {SharedModule} from '../../../shared/shared.module';
import {RouterModule} from '@angular/router';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {InlineSVGModule} from 'ng-inline-svg';
import { ScrollTopComponent } from './scroll-top/scroll-top.component';
import {NgbDropdownModule} from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [
    NoticeComponent,
    SplashScreenComponent,
    SubheaderWrapperComponent,
    ScrollTopComponent,
  ],
  exports: [SubheaderWrapperComponent, NoticeComponent, SplashScreenComponent, ScrollTopComponent],
  imports: [
    CommonModule,
    FormsModule,
    InlineSVGModule,
    NgbDropdownModule,
    SharedModule,
    RouterModule.forChild([]),
    StoreModule.forRoot({}),
],
  providers: [],
})
export class ControlModule {

}
