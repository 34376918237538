// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  env: 'development',
  apiUrl: 'https://hidtest.stc.vn:8643',
  facebookAppId: '1101023373682553',
  firebase: {
    apiKey: "AIzaSyClPL2nFwcRw816rORKUn4KTRkxziA9iuI",
    authDomain: "prm-prj.firebaseapp.com",
    databaseURL: "https://prm-prj.firebaseio.com",
    projectId: "prm-prj",
    storageBucket: "prm-prj.appspot.com",
    messagingSenderId: "533885649295",
    appId: "1:533885649295:web:a50f68346cc29ddf494bfa"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
