import {Component, Input, OnInit} from '@angular/core';
import {Constant} from '../../../shared/constants/constant.class';
import {AuthService} from '../../../service/auth.service';
import {Router} from '@angular/router';
import {VekoAccount} from '../../../model/account';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent implements OnInit {
  extrasUserDisplay: boolean;
  extrasUserLayout: 'offcanvas' | 'dropdown';
  _user: VekoAccount;
  extrasNotificationsDisplay: boolean;
  @Input() layoutType: any;
  constructor(
    private authService: AuthService,
    private route: Router,
  ) {
    this.extrasNotificationsDisplay = true;
  }

  ngOnInit(): void {
    this._user = JSON.parse(localStorage.getItem(Constant.USER_INFO));
    this.extrasUserLayout = 'dropdown';
  }
  logout() {
    localStorage.removeItem(Constant.TOKEN);
    localStorage.removeItem(Constant.USER_INFO);
    this.route.navigate(['/admin-login']);
    /*this.authService.logout().subscribe(res => {
      localStorage.removeItem(Constant.TOKEN);
      localStorage.removeItem(Constant.USER_INFO);
      this.route.navigate(['/admin-login']);
      if (this.layoutType === "ADMIN")
        this.route.navigate(['/admin-login']);
      /!*else if (this.layoutType === 'PORTAL')
        this.route.navigate(['/login']);
      else if (this.layoutType === 'CSC')
        this.route.navigate(['/csc-login']);*!/
    });*/
  }

}
