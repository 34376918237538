import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {select, Store} from '@ngrx/store';
import * as fromAuth from '../redux/auth.reducer';
import * as actionAuth from '../redux/auth.action';
import {AuthModel} from '../../../model/auth.model';
import {Observable, Subscription} from 'rxjs';
import {Constant} from '../../../shared/constants/constant.class';
import {AuthService} from '../../../service/auth.service';
import {User} from '../../../model/user.class';
import {HttpParams} from '@angular/common/http';
import {UserService} from '../../../service/user-service';
import {error} from 'selenium-webdriver';
import {Cookie} from 'ng2-cookies';
import {GeneralService} from '../../../service/general-service';
import {AccountService} from '../../../service/account-service';
import {GoogleLoginProvider, SocialAuthService} from 'angularx-social-login';
//import {LANDING_MENU_TOP} from "../../landing-layout/detail-page/mock_landing_menu";
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit , OnDestroy {
  validateForm: FormGroup;
  returnUrl: string;
  messageError: string;
  sub: Subscription;
  user: User;
  auth: AuthModel;
  tabs: any[];
  hasError: boolean;
  isShowMenu: boolean;
  isShowRightMenu: boolean;
  menus: any[];
  username: any;
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private store: Store<fromAuth.AppState>,
    private route: ActivatedRoute,
    private authService: AuthService,
    private userService: UserService,
    private generalService: GeneralService,
    private accountService: AccountService,
    private socialAuthService: SocialAuthService
    ) {
    //this.menus = LANDING_MENU_TOP;
  }
  ngOnDestroy(): void {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }
  ngOnInit(): void {
    this.validateForm = this.fb.group({
      username: [null, [Validators.required]],
      password: [null, [Validators.required]],
    });
    // this.returnUrl = this.route.snapshot.queryParams.returnUrl || Constant.PORTAL_DASHBOARD;
    // const currentUser = Cookie.get(Constant.TOKEN);
    // //console.log(currentUser);
    // if (currentUser) {
    //   this.router.navigate([Constant.PORTAL_DASHBOARD]);
    // }
  }
  submitForm(): void {
    alert('vao day');
    this.sub = this.authService.login(this.validateForm.value).subscribe(res => {
      if (res !== null)
      {
        this.accountService.doLogin(res, this.returnUrl);
      }
      // tslint:disable-next-line:no-shadowed-variable
    }, error => {
        if (error.error && error.error.message) {
          this.messageError = error.error.message;
        }
        else
          this.messageError = Constant.LOGIN_FAIL;
    });
  }
  facebookLogin(): void {
    this.accountService.login();
  }
  async googleLogin(): Promise<void> {
    this.accountService.googleLogin();
  }
  logout() {
    this.authService.logout().subscribe(res => {
      localStorage.removeItem(Constant.TOKEN);
      localStorage.removeItem(Constant.USER_INFO);
      location.href = '/';
    });
  }
  toggleMenu() {
    this.isShowMenu = !this.isShowMenu;
    if (this.isShowMenu)
      this.isShowRightMenu = false;
  }
  toggleRightMenu() {
    this.isShowRightMenu = !this.isShowRightMenu;
    if (this.isShowRightMenu)
      this.isShowMenu = false;
  }
}
