import {Injectable} from '@angular/core';
import {BaseService} from '../shared/base-service/base-service.service';
import {Observable} from 'rxjs';
import {UrlConstant} from '../shared/constants/url.class';
import {Role} from '../model/role.class';
import {Constant} from '../shared/constants/constant.class';

@Injectable()
export class GeneralService  extends  BaseService {

  //taikhoan
  getTaikhoan(): Observable<any[]> {
    return this.get(UrlConstant.LIST_TAIKHOAN);
  }
  deleteTaikhoan(id: number): Observable<number> {
    return this.delete(UrlConstant.LIST_TAIKHOAN + '/' + id, null);
  }
  addTaikhoan(item: any): any {
    return this.post(UrlConstant.LIST_TAIKHOAN, item);
  }
  updateTaikhoan(item: any): any {
    return this.put(UrlConstant.LIST_TAIKHOAN + '/' + item.id, item, 'text');
  }
  updateUserPassword(item: any): any {
    return this.post(UrlConstant.LIST_TAIKHOAN + '/ChangeUserPassword/' + item.id, item);
  }
  addGroup2User(item: any): any {
    return this.post(UrlConstant.LIST_TAIKHOAN + '/AddGroup2User', item);
  }
  getUserLogin(username, password): Observable<any[]> {
    return this.get('/test/getUserLogin?password=' + password + '&userId=' + username);
  }
  getDetailTaikhoan(id: number): Observable<any> {
    return this.get(UrlConstant.LIST_TAIKHOAN + '/' + id, null);
  }

  //upload
  upload(FormData: any): Observable<any> {
    return this.post('/file/upload', FormData);
  }

  //Role
  getRole(): Observable<any[]> {
    return this.get(UrlConstant.LIST_ROLE);
  }

  //Group
  getGroup(): Observable<any[]> {
    return this.get(UrlConstant.LIST_NHOMTAIKHOAN);
  }
  deleteGroup(id: number): Observable<number> {
    return this.delete(UrlConstant.LIST_NHOMTAIKHOAN + '/' + id, null);
  }
  addGroup(item: any): any {
    return this.post(UrlConstant.LIST_NHOMTAIKHOAN, item);
  }
  updateGroup(item: any): any {
    return this.put(UrlConstant.LIST_NHOMTAIKHOAN + '/' + item.id, item, 'text');
  }
  getGroupOfUser(userId: any): any {
    return this.get(UrlConstant.LIST_NHOMTAIKHOAN + '/GetGroupOfUser/' + userId);
  }


  addUserToGroup(item: any): any {
    return this.post(UrlConstant.LIST_NHOMTAIKHOAN + '/AddUser2Group', item);
  }
  removeUserFromGroup(item: any): any {
    return this.post(UrlConstant.LIST_NHOMTAIKHOAN + '/RemoveUserFromGroup', item);
  }
  getUserOfGroup(groupId: any): any {
    return this.get(UrlConstant.LIST_USER + '/GetUserOfGroup/' + groupId);
  }

  updateGroupRole(item: any): any {
    return this.post(UrlConstant.LIST_NHOMTAIKHOAN + '/UpdateGroupRoles', item);
  }

  ///Login/GetInitialData
  getInitialData(): any {
    return this.get('/Login/GetInitialData');
  }
  getSystemConfig(): any {
    return this.get('/SystemConfig');
  }
  updateSystemConfig(item: any): any {
    return this.put('/SystemConfig/' + item.id, item, 'text');
  }
  //Banner
  getBanner(): Observable<any[]> {
    return this.get(UrlConstant.LIST_BANNER);
  }
  deleteBanner(id: number): Observable<number> {
    return this.delete(UrlConstant.LIST_BANNER + '/' + id, null);
  }
  addBanner(item: any): any {
    return this.post(UrlConstant.LIST_BANNER, item);
  }
  updateBanner(item: any): any {
    return this.put(UrlConstant.LIST_BANNER + '/' + item.id, item, 'text');
  }

  loginFB(): any {
    return this.post('Account/ExternalLogin', {provider: 'Facebook'});
  }
//Quan ly an
  ///test/getListUpdateInfo
  getListUpdateInfo(item: any): Observable<any[]> {
    return this.post('/SppCase/getListUpdateInfo', item);
  }
  addBanAn(item: any): any {
    return this.post('/SppCase/insert', item);
  }
  // /dm/LstBorderGuards/getList
  getLstBorderGuards(search: any): Observable<any[]> {
    return this.get('/dm/LstBorderGuards/getList', search);
  }
  getLstBorderGuardsByQuery(keyword: any): Observable<any[]> {
    return this.get('/dm/LstBorderGuards/autocomplete/' + keyword, {query: keyword});
  }
  getListCaseLawBySppCase(search: any): any {
    return this.get('/SppCase/getListCaseLaw', search);
  }
  getListRegisterBySppCase(search: any): any {
    return this.get('/SppRegister/getList', search);
  }
  saveRegister(item: any): any {
    return this.post('/SppRegister/save', item);
  }

  //customer
  getCustomer(id: any): Observable<any> {
    return this.get(UrlConstant.LIST_CUSTOMER + '/' + id, null);
  }
  getCustomers(payload): Observable<any> {
    return this.get(UrlConstant.LIST_CUSTOMER, payload);
  }
  addCustomer(item: any): any {
    return this.post(UrlConstant.LIST_CUSTOMER, item);
  }
  updateCustomer(item: any): any {
    return this.put(UrlConstant.LIST_CUSTOMER + '/' + item.id, item, 'text');
  }
  deleteCustomer(id: number): Observable<number> {
    return this.delete(UrlConstant.LIST_CUSTOMER + '/' + id, null);
  }
  searchCustomer(query: any, pageNo: number, pageSize: number): Observable<any[]> {
    return this.get(`${UrlConstant.LIST_CUSTOMER}/query?query=${query}&pageNo=${pageNo}&pageSize=${pageSize}`, null);
  }
  getCustomerTransactions(payload): Observable<any> {
    return this.post(UrlConstant.LIST_CUSTOMER + '/CustomerTransactions', payload);
  }

  //partner
  getPartners(): Observable<any[]> {
    return this.get(UrlConstant.LIST_PARTNER);
  }
  addPartner(item: any): any {
    return this.post(UrlConstant.LIST_PARTNER, item);
  }
  updatePartner(item: any): any {
    return this.put(UrlConstant.LIST_PARTNER + '/' + item.id, item, 'text');
  }

  //releasePoint
  getReleasePoints(): Observable<any[]> {
    return this.get('/ReleasePoints');
  }
  //pointReport
  getPointReport(timeValue: number): Observable<any[]> {
    return this.get(`/PointTransactions/GetPointReport?timeValue=${timeValue}`, null)
  }
  getPointReportByDate(from: string, to: string): Observable<any[]> {
    return this.get(`/PointTransactions/GetPointReportByDate?from=${from}&to=${to}`, null)
  }
  GetListOrderInDay(listOrderId: number[]): Observable<any> {
    return this.post('/PointTransactions/GetListOrderInDay', listOrderId)
  }

  //voucher
  getVouchers(payload): Observable<any> {
    return this.get(UrlConstant.LIST_VOUCHER, payload);
  }
  getVoucher(id: any): Observable<any> {
    return this.get(UrlConstant.LIST_VOUCHER + '/' + id, null);
  }
  generateVoucher(item: any): Observable<any> {
    return this.post(UrlConstant.LIST_VOUCHER + '/Generate', item);
  }
  activateVoucher(item: any): Observable<any> {
    return this.post(UrlConstant.LIST_VOUCHER + '/Activate', item);
  }
  useVoucher(code: string): Observable<any> {
    return this.post(UrlConstant.LIST_VOUCHER + '/Use/' + code, code);
  }

  //account
  getAccount(id: number): Observable<number> {
    return this.get(UrlConstant.LIST_ACCOUNT + '/' + id, null)
  }
  getSystemAccount(): Observable<any> {
    return this.get(UrlConstant.LIST_ACCOUNT + '/system')
  }
  getReleasedAccount(): Observable<any> {
    return this.get(UrlConstant.LIST_ACCOUNT + '/released')
  }
  getPromotionAccount(): Observable<any> {
    return this.get(UrlConstant.LIST_ACCOUNT + '/Promotion')
  }
  putReleasedPoint(releasedPoint: number, reason: string): any {
    return this.put(UrlConstant.LIST_ACCOUNT + '/releasedPoint?' + 'releasedPoint=' + releasedPoint + '&reason=' + reason, releasedPoint);
  }
  //order
  addOrder(item: any): any {
    return this.post(UrlConstant.LIST_ORDER, item);
  }

  checkPermission(per){
    const listPermission = new Set();
    const userInfo = JSON.parse(localStorage.getItem(Constant.USER_INFO));
    if (userInfo.roles){
      for (let i = 0; i < userInfo.roles.length; i++) {
        listPermission.add(userInfo.roles[i]);
      }
    }
    return listPermission.has(per);
  }
  //cancel point
  cancelPoint(item: any): any {
    return this.post('/CancelPoints', item);
  }
}
