import { Component, OnInit } from '@angular/core';
import {NotificationService} from '../../../../service/notification.service';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder} from '@angular/forms';
import {AppConfigService} from '../../../../../app-config.service';
import {GeneralService} from '../../../../service/general-service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  currentTab = 'Day';
  private subscriptions: Subscription[] = [];
  datas: any[];
  loading: boolean;
  filterItem = {
    "pageSize": 5,
    "rowIndex": 0,
    "sortField": "",
    "sortOrder": "",
    "sppId": "",
    sppcase : {
      casename: '',
      casecode: '',
      begin_setnum: ''
    }
  };
  constructor(
    private notificationService: NotificationService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private configService: AppConfigService,
    private generalService: GeneralService,

    private router: Router
  ) { }

  ngOnInit(): void {
    this.getListData();
  }
  setCurrentTab(tab: string) {
    this.currentTab = tab;
  }
  getListData() {

    this.generalService.getLstBorderGuardsByQuery("Hải An").subscribe(res => {
      console.log(res);
      // alert(res.length);
    }, error => {
      alert("Lỗi dữ liệu");
    });
    this.loading = true;
    //console.log('initData', this.userInfo);
    this.filterItem.sppId =  '01';
    this.generalService.getListUpdateInfo(this.filterItem).subscribe(res => {
      this.loading = false;
      // console.log(res);
      this.datas = res;
    }, error => {
      alert("Lỗi dữ liệu");
    });
  }
}
