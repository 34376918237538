import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';
import {User} from '../../../model/user.class';
import {AuthModel} from '../../../model/auth.model';
import {ActivatedRoute, Router} from '@angular/router';
import {Store} from '@ngrx/store';
import * as fromAuth from '../redux/auth.reducer';
import {AuthService} from '../../../service/auth.service';
import {UserService} from '../../../service/user-service';
import {GeneralService} from '../../../service/general-service';
import {AccountService} from '../../../service/account-service';
import {SocialAuthService} from 'angularx-social-login';
import {Constant} from '../../../shared/constants/constant.class';
import {Cookie} from 'ng2-cookies';

@Component({
  selector: 'app-login-admin',
  templateUrl: './login-admin.component.html',
  styleUrls: ['./login-admin.component.scss']
})
export class LoginAdminComponent implements OnInit , OnDestroy {
  validateForm: FormGroup;
  returnUrl: string;
  messageError: string;
  sub: Subscription;
  user: User;
  auth: AuthModel;
  tabs: any[];
  hasError: boolean;
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private store: Store<fromAuth.AppState>,
    private route: ActivatedRoute,
    private authService: AuthService,
    private userService: UserService,
    private generalService: GeneralService,
    private accountService: AccountService,
    private socialAuthService: SocialAuthService

  ) {
  }
  ngOnDestroy(): void {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }
  ngOnInit(): void {
    this.validateForm = this.fb.group({
      username: [null, [Validators.required]],
      password: [null, [Validators.required]],
    });
    this.returnUrl = this.route.snapshot.queryParams.returnUrl || Constant.ADMIN_DASHBOARD;
    const currentUser = Cookie.get(Constant.TOKEN);
    if (currentUser) {
      this.router.navigate([Constant.ADMIN_DASHBOARD]);
    }
  }
  submitForm(): void {
    this.sub = this.authService.login(this.validateForm.value).subscribe(res => {
      if (res !== null)
      {
        this.accountService.doLogin(res, this.returnUrl);
      }
    }, error => {
      if (error.error && error.error.message) {
        this.messageError = error.error.message;
      }
      else
        this.messageError = Constant.LOGIN_FAIL;
    });
  }
  facebookLogin(): void {
    this.accountService.login();
  }
  async googleLogin(): Promise<void> {
    this.accountService.googleLogin();
  }
}
