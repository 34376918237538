export class AppConsts {

    static api: {
        baseUrl: string
    };
    static apisso: {
      baseUrl: string
  };
    static pageSize: {
      size: any
  };
  static site: {
    baseUrl: string,
    imageUrl: string
  };
  static page: { basePage: number };
  static defaultPage: { defaultBasePage: number };
  
}
