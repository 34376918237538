import {Component, Input, OnInit} from '@angular/core';
import { VekoAccount } from 'src/app/model/account';
import { Constant } from 'src/app/shared/constants/constant.class';
import {LayoutService} from '../../../service/core/layout.service';
import {FAKE_MENUS_ADMIN} from './mock_menu';

@Component({
  selector: 'app-aside',
  templateUrl: './aside.component.html',
  styleUrls: ['./aside.component.scss']
})
export class AsideComponent implements OnInit {
  @Input() layoutType: any;
  disableAsideSelfDisplay: boolean;
  headerLogo: string;
  brandSkin: string;
  ulCSSClasses: string;
  location: Location;
  asideMenuHTMLAttributes: any = {};
  asideMenuCSSClasses: string;
  asideMenuDropdown;
  brandClasses: string;
  asideMenuScroll = 1;
  asideSelfMinimizeToggle = false;
  menus: any[];
  constructor(
    private layout: LayoutService) {

  }
  ngOnInit(): void {
    const user = JSON.parse(localStorage.getItem(Constant.USER_INFO));
    // @ts-ignore
    this.menus =  user.menus;
    /*
    if (this.layoutType === 'ADMIN')
      this.menus = FAKE_MENUS_ADMIN;
    else if (this.layoutType === 'PORTAL')
      this.menus = user.portalMenus;
    else if (this.layoutType === 'CSC')
      this.menus = user.cscMenus;*/

    this.disableAsideSelfDisplay =
      this.layout.getProp('aside.self.display') === false;
    this.brandSkin = this.layout.getProp('brand.self.theme');
    this.headerLogo = this.getLogo();
    this.ulCSSClasses = this.layout.getProp('aside_menu_nav');
    this.asideMenuCSSClasses = this.layout.getStringCSSClasses('aside_menu');
    this.asideMenuHTMLAttributes = this.layout.getHTMLAttributes('aside_menu');
    this.asideMenuDropdown = this.layout.getProp('aside.menu.dropdown') ? '1' : '0';
    this.brandClasses = this.layout.getProp('brand');
    this.asideSelfMinimizeToggle = this.layout.getProp(
      'aside.self.minimize.toggle'
    );
    this.asideMenuScroll = this.layout.getProp('aside.menu.scroll') ? 1 : 0;
    this.asideMenuCSSClasses = `${this.asideMenuCSSClasses} ${this.asideMenuScroll === 1 ? 'scroll my-4 ps ps--active-y' : ''}`;
  }
  private getLogo() {
    /*if (this.layoutType === 'ADMIN') {
      return './assets/image/logo_dark.png';
    } else {
      return './assets/image/logo_dark.png';
    }*/
    return '';
  }
}
