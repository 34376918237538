import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {UserService} from '../../../service/user-service';
import {AccountService} from '../../../service/account-service';
import {Constant} from '../../../shared/constants/constant.class';

@Component({
  selector: 'app-google-auth',
  templateUrl: './google-auth.component.html'
})
export class GoogleAuthComponent implements OnInit {

  constructor(
    private route:ActivatedRoute,
    private userService: UserService,
    private accountService: AccountService
              ){

  }

  ngOnInit(): void {
    var token = this.route.snapshot.paramMap.get('token');
    this.accountService.apiGoogleAuthenticate(token);
    this.userService.googleAuthenticate(token).subscribe(res => {
      if (res !== null && (!res.ret || res.ret[0].code !== 401))
      {
        this.accountService.doLogin(res, '');
      }
      else{
        location.href = '/';
      }
    }, error => {
      //this.messageError = Constant.LOGIN_FAIL;
    });
  }

}
