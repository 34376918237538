import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from '../../../service/user-service';

@Component({
  selector: 'app-success-verify',
  templateUrl: './success-verify.component.html',
  styleUrls: ['./success-verify.component.scss']
})
export class SuccessVerifyComponent implements OnInit {
  success = false;
  username = '';
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private portalService: UserService
  ) { }

  ngOnInit(): void {
    const code = this.route.snapshot.paramMap.get('code');
    this.portalService.confirmUser(code).subscribe(res => {
      if (res.ret) {
        this.router.navigate(['/']);
      }
      this.username = res.username;
      this.success = true;
    }, error => {

    });
  }

}
