<!--begin::Brand-->
<div class="brand flex-column-auto" id="kt_brand" [ngClass]="brandClasses">
  <!--begin::Logo-->
  <a routerLink="/" class="brand-logo">
    <img alt="Logo" [attr.src]="headerLogo" style="height: 40px" />
    <span>HID</span>
  </a>
  <!--end::Logo-->

  <ng-container *ngIf="asideSelfMinimizeToggle">
    <button class="brand-toggle btn btn-sm px-0" id="kt_aside_toggle">
      <span
        [inlineSVG]="
          './assets/media/svg/icons/Navigation/Angle-double-left.svg'
        "
        cacheSVG="true"
        class="svg-icon svg-icon-xl"
      ></span>
    </button>
  </ng-container>
</div>
<!--end::Brand-->

<!--begin::Aside Menu-->
<div class="aside-menu-wrapper flex-column-fluid" id="kt_aside_menu_wrapper">
  <ng-container *ngIf="disableAsideSelfDisplay">
    <!--begin::Header Logo-->
    <a routerLink="/">
      <img alt="Logo" [attr.src]="headerLogo" />
    </a>
    <!--end::Header Logo-->
  </ng-container>
  <!--begin::Menu Container-->
  <div
    id="kt_aside_menu"
    class="aside-menu"
    [ngClass]="asideMenuCSSClasses"
    data-menu-vertical="1"
    [attr.data-menu-scroll]="asideMenuScroll"
    [attr.data-menu-dropdown]="asideMenuDropdown"
    data-menu-dropdown-timeout="500">
    <!-- begin::Menu Nav -->
    <ul class="menu-nav" [ngClass]="ulCSSClasses">
      <!-- begin::1 Level -->
      <ng-container *ngFor="let m1 of menus">
        <li class="menu-section">
          <h4 class="menu-text">{{m1.name}}</h4>
          <i class="menu-icon flaticon-more-v2"></i>
        </li>
        <ng-container *ngFor="let m2 of m1.children">
          <!--Menu no children-->
          <li *ngIf="!m2.children || m2.children.length === 0"
              class="menu-item"
              aria-haspopup="true"
              routerLinkActive="menu-item-active">
            <a class="menu-link" routerLink="{{m2.path}}" routerLinkActive="active">
              <span [inlineSVG]="'./assets/media/svg/icons/Design/Layers.svg'" cacheSVG="true" class="svg-icon menu-icon"></span>
              <span class="menu-text">{{m2.name}}</span>
            </a>
          </li>
          <!--Menu has children-->
          <li *ngIf="m2.children && m2.children.length > 0"
            class="menu-item menu-item-submenu"
            aria-haspopup="true"
            data-menu-toggle="hover"
            routerLinkActive="menu-item-open">
            <a
              href="{{m2.path}}"
              class="menu-link menu-toggle"
              routerLinkActive="active">
              <span [inlineSVG]="'./assets/media/svg/icons/Design/Cap-2.svg'" cacheSVG="true" class="svg-icon menu-icon"></span>
              <span class="menu-text">{{m2.name}}</span>
              <i class="menu-arrow"></i>
            </a>
            <div class="menu-submenu">
              <i class="menu-arrow"></i>
              <ul class="menu-subnav">
                <li class="menu-item menu-item-parent" aria-haspopup="true">
                  <span class="menu-link">
                    <span class="menu-text">{{m2.name}}</span>
                  </span>
                </li>
                <!-- Form Controls -->
                <!-- begin::3 Level -->
                <li *ngFor="let m3 of m2.children"
                  class="menu-item"
                  aria-haspopup="true"
                  routerLinkActive="menu-item-active">
                  <a
                    class="menu-link"
                    routerLink="{{m3.path}}"
                    routerLinkActive="active">
                    <i class="menu-bullet menu-bullet-dot">
                      <span></span>
                    </i>
                    <span class="menu-text">{{m3.name}}</span>
                  </a>
                </li>
                <!-- end::3 Level -->
              </ul>
            </div>
          </li>
        </ng-container>
      </ng-container>
      <!-- end::1 Level -->
    </ul>
    <!-- end::Menu Nav -->
  </div>
  <!--end::Menu Container-->
</div>
<!--end::Aside Menu-->
