import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {SamplePageComponent} from './pages/sample-page/sample-page.component';
import {AdminMasterComponent} from './master-page/admin-master/admin-master.component';
import {AuthGuard} from '../../shared/guards/guards.class';
import {DashboardComponent} from './pages/dashboard/dashboard.component';
import { LoginComponent } from '../auth/login/login.component';
import { PortalMasterComponent } from './master-page/portal-master/portal-master.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/admin',
    pathMatch: 'full'
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'dashboard',
    component: PortalMasterComponent,
  },
  {
    path: 'admin',
    component: AdminMasterComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'dashboard',
        component: DashboardComponent,
      },
      {
        path: 'welcome',
        loadChildren: () => import('../../shared/component/page-default/page-default.module').then(s => s.PageDefaultModule),
        data: {
          pagename: 'welcome',
          breadcrumb: 'Welcome'
        },
      },
      {
        path: 'taikhoan',
        loadChildren: () => import('./pages/taikhoan/taikhoan.module').then(m => m.TaikhoanModule),
        data: {
          pagename: 'User',
          breadcrumb: 'User Management'
        }
      },
      {
        path: 'nhomtaikhoan',
        loadChildren: () => import('./pages/nhomtaikhoan/nhomtaikhoan.module').then(m => m.NhomtaikhoanModule),
        data: {
          pagename: 'Group user',
          breadcrumb: 'Group user'
        }
      },
      {
        path: 'banner',
        loadChildren: () => import('./pages/banner/banner.module').then(m => m.BannerModule),
        data: {
          pagename: 'Banner',
          breadcrumb: 'Banner'
        }
      },
      {
        path: 'customer',
        loadChildren: () => import('./pages/customer/customer.module').then(m => m.CustomerModule),
        data: {
          pagename: 'Customer',
          breadcrumb: 'Danh sách khách hàng'
        }
      },
      {
        path: 'config',
        loadChildren: () => import('./pages/system-config/system-config.module').then(m => m.SystemConfigModule),
        data: {
          pagename: 'System config',
          breadcrumb: 'Cấu hình hệ thống'
        }
      },
      {
        path: 'point',
        loadChildren: () => import('./pages/point/point.module').then(m => m.PointModule),
        data: {
          pagename: 'Point',
          breadcrumb: 'Tích điểm'
        }
      },
      {
        path: 'account',
        loadChildren: () => import('./pages/account/account.module').then(m => m.AccountModule),
        data: {
          pagename: 'Account',
          breadcrumb: 'Tài khoản'
        }
      },
      {
        path: 'pointReport',
        loadChildren: () => import('./pages/point-report/point-report.module').then(m => m.PointReportModule),
        data: {
          pagename: 'Point Report',
          breadcrumb: 'Thống kê hoạt động'
        }
      },
      {
        path: 'voucher',
        loadChildren: () => import('./pages/voucher/voucher.module').then(m => m.VoucherModule),
        data: {
          pagename: 'Voucher',
          breadcrumb: 'Quản lý voucher'
        }
      },
    ]
  }
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminLayoutRoutingModule {
}
