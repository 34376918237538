import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-notifications-dropdown-inner',
  templateUrl: './notifications-dropdown-inner.component.html',
  styleUrls: ['./notifications-dropdown-inner.component.scss']
})
export class NotificationsDropdownInnerComponent implements OnInit {
  activeTabId:
    | 'events'
    | 'chats' = 'events';
  constructor() { }

  ngOnInit(): void {
  }
  setActiveTabId(tabId) {
    this.activeTabId = tabId;
  }

  getActiveCSSClasses(tabId) {
    if (tabId !== this.activeTabId) {
      return '';
    }
    return 'active show';
  }
}
