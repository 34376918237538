<ng-container *ngIf="extrasNotificationsDisplay">
  <ng-container>
    <!--begin::Notifications-->
    <div class="dropdown" ngbDropdown placement="bottom">
      <!--begin::Toggle-->
      <div class="topbar-item" data-toggle="dropdown" data-offset="10px,0px" ngbDropdownToggle>
        <span class="noti-number">12</span>
        <div class="btn btn-icon btn-clean btn-dropdown btn-lg mr-1 pulse pulse-primary">
          <span [inlineSVG]="'./assets/media/svg/icons/Code/Compiling.svg'" cacheSVG="true"
                class="svg-icon svg-icon-xl svg-icon-primary"></span>
          <span class="pulse-ring"></span>
        </div>
      </div>
      <!--end::Toggle-->

      <!--begin::Dropdown-->
      <div ngbDropdownMenu class="dropdown-menu p-0 m-0 dropdown-menu-anim-up dropdown-menu-lg">
        <form>
          <app-notifications-dropdown-inner></app-notifications-dropdown-inner>
        </form>
      </div>
      <!--end::Dropdown-->
    </div>
    <!--end::Notifications-->
  </ng-container>
</ng-container>

<app-language-selector style="margin-top: 10px;"></app-language-selector>

<div class="dropdown" ngbDropdown placement="bottom-right">
  <div class="topbar-item" data-toggle="dropdown" data-offset="0px,0px" ngbDropdownToggle>
    <div class="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2">
      <span class="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1">Hi,</span>
      <span
        class="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3">{{ _user.fullname }}</span>
      <span class="symbol symbol-35 symbol-light-success">
          <span class="symbol-label font-size-h5 font-weight-bold">{{
            _user.username | firstLetter
            }}</span>
        </span>
    </div>
  </div>
  <!--end::Toggle-->
  <!--begin::Dropdown-->
  <div ngbDropdownMenu
       class="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-lg p-0">
    <!--Dropdown container-->
    <ng-container>
      <ng-container>
        <!--begin::Header-->
        <div
          class="d-flex align-items-center justify-content-between flex-wrap p-8 bgi-size-cover bgi-no-repeat rounded-top"
          style="background-image: url('./assets/media/misc/bg-1.jpg');"
        >
          <div class="d-flex align-items-center mr-2">
            <!--begin::Symbol-->
            <div class="symbol bg-white-o-15 mr-3">
          <span
            class="symbol-label text-success font-weight-bold font-size-h4"
          >{{ _user.username | firstLetter }}</span
          >
            </div>
            <!--end::Symbol-->

            <!--begin::Text-->
            <div class="text-white m-0 flex-grow-1 mr-3 font-size-h5">
              {{ _user.fullname }}
            </div>
            <!--end::Text-->
          </div>
          <span class="label label-success label-lg font-weight-bold label-inline"
          >3 messages</span
          >
        </div>
        <!--end::Header-->
      </ng-container>

      <!--begin::Nav-->
      <div class="navi navi-spacer-x-0 pt-5">
        <!--begin::Item-->
        <a class="navi-item px-8 cursor-pointer" routerLink="/user-profile">
          <div class="navi-link">
            <div class="navi-icon mr-2">
              <i class="flaticon2-calendar-3 text-success"></i>
            </div>
            <div class="navi-text">
              <div class="font-weight-bold">
                My Profile
              </div>
              <div class="text-muted">
                Account settings and more
                <span class="label label-light-danger label-inline font-weight-bold"
                >update</span
                >
              </div>
            </div>
          </div>
        </a>
        <!--end::Item-->

        <!--begin::Item-->
        <a class="navi-item px-8 cursor-pointer">
          <div class="navi-link">
            <div class="navi-icon mr-2">
              <i class="flaticon2-mail text-warning"></i>
            </div>
            <div class="navi-text">
              <div class="font-weight-bold">
                My Messages
              </div>
              <div class="text-muted">
                Inbox and tasks
              </div>
            </div>
          </div>
        </a>
        <!--end::Item-->

        <!--begin::Item-->
        <a class="navi-item px-8 cursor-pointer">
          <div class="navi-link">
            <div class="navi-icon mr-2">
              <i class="flaticon2-rocket-1 text-danger"></i>
            </div>
            <div class="navi-text">
              <div class="font-weight-bold">
                My Activities
              </div>
              <div class="text-muted">
                Logs and notifications
              </div>
            </div>
          </div>
        </a>
        <!--end::Item-->

        <!--begin::Item-->
        <a class="navi-item px-8 cursor-pointer">
          <div class="navi-link">
            <div class="navi-icon mr-2">
              <i class="flaticon2-hourglass text-primary"></i>
            </div>
            <div class="navi-text">
              <div class="font-weight-bold">
                My Tasks
              </div>
              <div class="text-muted">
                latest tasks and projects
              </div>
            </div>
          </div>
        </a>
        <!--end::Item-->

        <!--begin::Footer-->
        <div class="navi-separator mt-3"></div>
        <div class="navi-footer px-8 py-5">
          <a
            class="btn btn-light-primary font-weight-bold cursor-pointer"
            (click)="logout()"
          >Sign Out</a
          >
          <a class="btn btn-clean font-weight-bold cursor-pointer">Upgrade Plan</a>
        </div>
        <!--end::Footer-->
      </div>
      <!--end::Nav-->
    </ng-container>


  </div>
</div>
<!--end::Dropdown-->
