<!--begin::Header-->
<div class="portal-wrapper menu-wrapper">
  <div class="container">
    <div class="row header">
      <div class="box_menu col-md-9 col-lg-7">
        <a class="l-logo" href="/"><img class="max-h-70px" style="height: 30px;margin-top: 6px"
                                        src="./assets/image/logo_dark.png"></a>
        <a (click)="toggleMenu()" class="btn-menu">

          <span *ngIf="isShowMenu" class="fa fa-fw fa-times"></span>
          <span *ngIf="!isShowMenu" class="fa fa-fw fa-bars"></span>
          <!--{{isShowMenu ? 'Hide' : 'Show'}} Menu-->
        </a>
        <ul nz-menu nzMode="horizontal" class="d-menu">
          <ng-container *ngFor="let child1 of menus">
            <li nz-menu-item *ngIf="child1.children.length === 0">
              <a [href]="child1.path">{{child1.name}}</a>
            </li>
            <li nz-submenu [nzTitle]="child1.name" *ngIf="child1.children.length > 0">
              <ul>
                <li nz-menu-item *ngFor="let child2 of child1.children">
                  <a [href]="child2.path">{{child2.name}}</a>
                </li>
              </ul>
            </li>
          </ng-container>
          <ng-container >
            <li nz-submenu nzTitle="My Account">
              <ul>
                <li nz-menu-item>
                  <a href="/portal/profile">Profile</a>
                </li>
                <li nz-menu-item>
                  <a href="/portal/address-book">Address Book</a>
                </li>
                <li nz-menu-item>
                  <a href="/portal/dashboard">Shipping Dashboard</a>
                </li>
              </ul>
            </li>
          </ng-container>
        </ul>
        <ul class="m-menu" *ngIf="isShowMenu">
          <ng-container *ngFor="let child1 of menus">
            <li *ngIf="child1.children.length === 0">
              <a [href]="child1.path">{{child1.name}}</a>
            </li>
            <li *ngIf="child1.children.length > 0">
              <a [href]="child1.path">{{child1.name}}</a>
              <ul>
                <li *ngFor="let child2 of child1.children">
                  <a [href]="child2.path">{{child2.name}}</a>
                </li>
              </ul>
            </li>
          </ng-container>
          <ng-container >
            <li>
              <a>My Account</a>
              <ul>
                <li>
                  <a href="/portal/profile">My Profile</a>
                </li>
                <li>
                  <a href="/portal/address-book">Address Book</a>
                </li>
                <li>
                  <a href="/portal/Dashboard">Shipping Dashboard</a>
                </li>
              </ul>
            </li>
          </ng-container>
        </ul>
      </div>
      <div class="col-md-3 div-auth col-lg-5" *ngIf="!username">
        <div class="btn-right">
          <!-- <app-language-selector-hoz></app-language-selector-hoz> -->
          <a class="btn-login" href="/login">
            <!--            <span class="fa fa-fw fa-key"></span>-->
            <span>{{ "LOGIN" | translate }}</span>
          </a>
          <a class="btn-signup" href="/registration" style="background: #EEE;">
            <!--            <span class="fa fa-fw fa-sign-in-alt"></span>-->
            <span>{{ "SIGNUP" | translate }}</span></a>
        </div>
      </div>
      <div class="col-md-3 div-auth col-lg-5" *ngIf="username">
        <div class="btn-right">
          <!-- <app-language-selector-hoz></app-language-selector-hoz> -->
          <a style="background: #EEE;" (click)="logout()">
            <span class="fa fa-fw fa-sign-out-alt"></span>
            <span>{{ "LOGOUT" | translate }}</span>
          </a>
          <a class="btn-login" href="/portal/dashboard">
            <span class="fa fa-fw fa-user"></span>
            <span>{{username}}</span>
          </a>
        </div>
      </div>
      <a (click)="toggleRightMenu()" class="btn-menu-right" style="display: none">
        <span *ngIf="isShowRightMenu" class="fa fa-fw fa-times"></span>
        <span *ngIf="!isShowRightMenu" class="fa fa-fw fa-user"></span>
      </a>
      <div class="menu-right" style="display: none;" *ngIf="isShowRightMenu">
        <!-- <app-language-selector></app-language-selector> -->
        <ul>
          <li *ngIf="!username">
            <a class="btn-login" href="/login" >
              <span class="fa fa-fw fa-key"></span>
              <span>{{"LOGIN" | translate }}</span>
            </a>
          </li>
          <li *ngIf="!username">
            <a href="/registration">
              <span class="fa fa-fw fa-sign-in-alt"></span>
              <span>{{"SIGNUP" | translate }}</span></a>
          </li>
          <li *ngIf="username">
            <a class="btn-login" href="/portal/dashboard">
              <span class="fa fa-fw fa-user"></span>
              <span>{{username}}</span>
            </a>
          </li>
          <li *ngIf="username">
            <a (click)="logout()">
              <span class="fa fa-fw fa-sign-out-alt"></span>
              <span>{{"LOGOUT" | translate }}</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<div class="portal-wrapper">
  <div style="background: url(./assets/image/bgdown.png);background-repeat: no-repeat;background-size: auto;background-position: bottom;">

    <!--begin::Signin-->
    <div class="login-form login-signin">
      <!--begin::Form-->
      <div class="row">
        <div class="col-md-5">
          <form
            class="form"
            [formGroup]="validateForm"
            novalidate="novalidate"
            id="kt_login_signin_form"
            (ngSubmit)="submitForm()"
          >
            <!--begin::Title-->
            <div class="pt-lg-0 pt-5">
              <h3 class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg" style="color: #535AA2 !important; letter-spacing: -1px;">
                Welcome to HID! Please login
              </h3>
              <span class="text-muted font-weight-bold font-size-h4"
              >New Here?
        <a
          routerLink="/registration"
          id="kt_login_signup"
          class="text-primary font-weight-bolder"
        >{{ "SIGNUP" | translate }}</a>
      </span>
            </div>
            <!--end::Title-->

            <!-- begin::Alert info-->
            <!--<ng-container *ngIf="!hasError">
              <div class="mb-10 alert alert-custom alert-light-info alert-dismissible">
                <div class="alert-text">
                  Use account <strong>{{ defaultAuth.email }}</strong> and password
                  <strong>{{ defaultAuth.password }}</strong> to continue.
                </div>
              </div>
            </ng-container>-->
            <!-- end::Alert info-->

            <!-- begin::Alert error-->
            <ng-container *ngIf="messageError">
              <div
                class="mb-10 alert alert-custom alert-light-danger alert-dismissible"
              >
                <div class="alert-text">{{messageError}}</div>
              </div>
            </ng-container>
            <!-- end::Alert error-->

            <!--begin::Form group-->
            <div class="form-group">
              <label class="font-size-h6 font-weight-bolder text-dark">Email</label>
              <input
                class="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
                type="email"
                tabindex="1"
                placeholder="{{ 'pls_enter_email' | translate }}"
                name="email"
                formControlName="username"
                autocomplete="off"
                [ngClass]="{ 'is-invalid': validateForm.controls['username'].invalid }"
              />
              <ng-container
                [ngTemplateOutlet]="formError" s
                [ngTemplateOutletContext]="{
          validation: 'required',
          message: 'Username is required',
          control: validateForm.controls['username']
        }"
              ></ng-container>
              <ng-container
                [ngTemplateOutlet]="formError"
                [ngTemplateOutletContext]="{
          validation: 'username',
          message: 'Username is invalid',
          control: validateForm.controls['username']
        }"
              ></ng-container>
              <ng-container
                [ngTemplateOutlet]="formError"
                [ngTemplateOutletContext]="{
          validation: 'minLength',
          message: 'Username should have at least 3 symbols',
          control: validateForm.controls['username']
        }"
              ></ng-container>
              <ng-container
                [ngTemplateOutlet]="formError"
                [ngTemplateOutletContext]="{
          validation: 'maxLength',
          message: 'Username should have maximum 360 symbols',
          control: validateForm.controls['username']
        }"
              ></ng-container>
            </div>
            <!--end::Form group-->

            <!--begin::Form group-->
            <div class="form-group">
              <div class="d-flex justify-content-between mt-n5">
                <label class="font-size-h6 font-weight-bolder text-dark pt-5"
                >Password</label>

              </div>

              <input
                class="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
                type="password"
                placeholder="{{ 'pls_enter_password' | translate }}"
                name="password"
                tabindex="2"
                autocomplete="off"
                formControlName="password"
                [ngClass]="{ 'is-invalid': validateForm.controls['password'].invalid }"
              />
              <ng-container
                [ngTemplateOutlet]="formError"
                [ngTemplateOutletContext]="{
          validation: 'required',
          message: 'Password is required',
          control: validateForm.controls['password']
        }"
              ></ng-container>
              <ng-container
                [ngTemplateOutlet]="formError"
                [ngTemplateOutletContext]="{
          validation: 'minlength',
          message: 'Password should have at least 3 symbols',
          control: validateForm.controls['password']
        }"
              ></ng-container>
              <ng-container
                [ngTemplateOutlet]="formError"
                [ngTemplateOutletContext]="{
          validation: 'maxLength',
          message: 'Password should have maximum 100 symbols',
          control: validateForm.controls['password']
        }"
              ></ng-container>

              <div class="d-flex justify-content-between mt-n5">
                <label class="font-size-h6 font-weight-bolder text-dark pt-5"
                ></label>
                <a
                  tabindex="-1"
                  href="/forgot-password"
                  class="text-primary font-size-h6 font-weight-bolder text-hover-primary pt-5"
                  id="kt_login_forgot"
                >
                  Forgot Password?
                </a>
              </div>
            </div>
            <!--end::Form group-->

            <!--begin::Action-->
            <div class="pb-lg-0 pb-5">
              <button style="width: 100%;background: #535AA2;border-color: #535AA2;"
                      type="submit"
                      id="kt_login_signin_submit"
                      class="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-3"
                      [disabled]="validateForm.invalid"
              >
                Login
              </button>


              <!--<ng-container *ngIf="isLoading$ | async">
                <span class="spinner spinner-primary ml-5"></span>
              </ng-container>-->
            </div>
            <!--end::Action-->
          </form>
        </div>
        <div class="col"></div>
        <div class="col"></div>
        <div class="col-md-5">
          <div class="or-seperator"><i>Or login with</i></div>
          <div class="pb-lg-0 pb-5">

                  <button (click)="googleLogin()" style="width: 100%"
                          type="button"
                          class="btn btn-light-primary font-weight-bolder px-8 py-4 my-3 font-size-lg"
                  >
              <span class="svg-icon svg-icon-md">
                <img src="./assets/media/svg/social-icons/google.svg" style="width: 20px"/>
              </span>
                    Google
                  </button>
                  <button (click)="facebookLogin()" style="width: 100%"
                          type="button"
                          class="btn btn-light-primary font-weight-bolder px-8 py-4 my-3 font-size-lg"
                  >
              <span class="svg-icon svg-icon-md">
                <img src="./assets/media/svg/social-icons/facebook2.svg" style="width: 20px"/>
              </span>
                    Facebook
                  </button>
              <!--<ng-container *ngIf="isLoading$ | async">
                <span class="spinner spinner-primary ml-5"></span>
              </ng-container>-->
          </div>
        </div>
      </div>

      <!--end::Form-->
    </div>
    <!--end::Signin-->

    <ng-template
      #formError
      let-control="control"
      let-message="message"
      let-validation="validation"
    >
      <ng-container
        *ngIf="control.hasError(validation) && (control.dirty || control.touched)"
      >
        <div class="fv-plugins-message-container">
          <div class="fv-help-block">
            {{ message }}
          </div>
        </div>
      </ng-container>
    </ng-template>
  </div>

</div>

<div class="footer">
  <div class="container">
    <div class="row">
      <div class="col-md-8">
        <ul>
          <li class="li_head">
            <a style="font-size: 26px;font-weight: 500;">Bệnh viện đa khoa Hùng Vương</a>
          </li>
          <li class="li_content"><a>Xã Chí Đám, Đoan Hùng, Phú Thọ, Việt Nam</a></li>
          <li class="li_content row">
            <div  class="col-md-4">
              <i class="fas fa-globe-africa"></i><a href="https://benhvienhungvuong.vn/" style="color: #1A1B1C;"> benhvienhungvuong.vn</a>
            </div>
            <div class="col-md-5">
              <i class="far fa-envelope"></i><a> cskh@benhvienhungvuong.vn</a>
            </div>
            <div  class="col-md-3">
              <i class="fas fa-phone-alt"></i><a> 091 564 2115</a>
            </div>
          </li>
        </ul>
      </div>


      <div class="col-md-4 social">
        <!-- <strong>FOLLOW US</strong>
        <ul>
          <li><a href="https://www.facebook.com/vekoi.vn/"><img src="./assets/svg/facebook.svg"></a>
          </li>
          <li>
            <a href="https://zalo.me/0342630444"><img src="./assets/svg/zalo.svg"></a>
          </li>


        </ul> -->
      </div>

    </div>
    <div>
      <!-- <div class="help-desk">
        <img width="80%" src="./assets/svg/chat.svg">
        <div class="contact">
          <img width="30px" src="./assets/svg/zalo.svg">
          <span style="color: #535AA2;font-weight: 500;">Nguyễn An</span>
        </div>
        <div class="contact">
          <img width="30px" src="./assets/svg/call.svg">
          <span style="color: #535AA2;font-weight: 500;">0903 438 666</span>
        </div>
      </div> -->
    </div>
  </div>
</div>

