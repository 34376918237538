import {AfterViewChecked, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {LoaderService} from './service/loader.service';

import { TableExtendedService } from 'src/app/component/admin-layout/shared/crud-table';
import {MessagingService} from './service/messaging.service';
import {NavigationEnd, Router} from '@angular/router';
import {SplashScreenComponent} from './component/admin-layout/controls/splash-screen/splash-screen.component';
import {SplashScreenService} from './component/admin-layout/controls/splash-screen/splash-screen.service';
import {Subscription} from 'rxjs';
import firebase from 'firebase';

@Component({
  selector: 'app-root',
  styleUrls: ['./app.component.scss'],
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, AfterViewChecked {
  private unsubscribe: Subscription[] = [];
  title = 'push-notification';
  message;
  constructor(
    private messagingService: MessagingService,
    private loaderService: LoaderService,
    private cdRef: ChangeDetectorRef,
    private router: Router,
    private splashScreenService: SplashScreenService,
    private tableService: TableExtendedService) {
  }
  ngOnInit(): void {
    const routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // clear filtration paginations and others
        this.tableService.setDefaults();
        // hide splash screen
        this.splashScreenService.hide();
        // scroll to top on every route change
        window.scrollTo(0, 0);

        // to display back the body content
        setTimeout(() => {
          document.body.classList.add('page-loaded');
          document.getElementById('html').className = 'loaded';
        }, 500);

        this.tableService.setDefaults();
        this.messagingService.requestPermission()
        this.messagingService.receiveMessage()
        this.message = this.messagingService.currentMessage

      }
    });
    this.unsubscribe.push(routerSubscription);
  }
  ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
  }
}
