import {AfterViewInit, Component, OnInit} from '@angular/core';
import { KTUtil } from '../../../../../assets/js/components/util';
import KTLayoutScrolltop from '../../../../../assets/js/layout/extended/scrolltop';
@Component({
  selector: 'app-scroll-top',
  styleUrls: ['./scroll-top.component.scss'],
  templateUrl: './scroll-top.component.html',
})
export class ScrollTopComponent implements OnInit, AfterViewInit {

  constructor() { }

  ngOnInit(): void {

  }
  ngAfterViewInit() {
    KTUtil.ready(() => {
      // Init Scrolltop
      KTLayoutScrolltop.init('kt_scrolltop');
    });
  }
}
