import {AfterViewInit, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {LayoutService} from '../../../../../service/core/layout.service';
import { SubheaderService } from '../_services/subheader.service';
import { KTUtil } from '../../../../../../assets/js/components/util';
import {BreadcrumbItemModel} from '../_models/breadcrumb-item.model';
import KTLayoutSubheader from '../../../../../../assets/js/layout/base/subheader';
import {ResolveEnd, Router} from '@angular/router';
import {filter} from 'rxjs/operators';
@Component({
  selector: 'app-subheader-wrapper',
  templateUrl: './subheader-wrapper.component.html'
})
export class SubheaderWrapperComponent implements OnInit, AfterViewInit {
  subheaderCSSClasses = '';
  subheaderContainerCSSClasses = '';
  subheaderMobileToggle = false;
  subheaderDisplayDesc = false;
  subheaderDisplayDaterangepicker = false;
  title$: Observable<string>;
  breadcrumbs$: Observable<BreadcrumbItemModel[]>;
  breadcrumbs: BreadcrumbItemModel[] = [];

  description$: Observable<string>;
  @Input() title: string;

  constructor(
    private layout: LayoutService,
    private subheader: SubheaderService,
    private cdr: ChangeDetectorRef,
    private router: Router
  ) {
    this.title$ = this.subheader.titleSubject.asObservable();
    console.log('this.title$', this.title$);
  }

  ngOnInit() {
    this.subheader.setDefaultSubheader();
    const initSubheader = () => {
      setTimeout(() => {
        this.subheader.updateAfterRouteChanges(this.router.url);
      }, 0);
    };

    initSubheader();
    // subscribe to router events
    this.router.events
      .pipe(filter((event) => event instanceof ResolveEnd))
      .subscribe(initSubheader);

    this.title$ = this.subheader.titleSubject.asObservable();
    this.breadcrumbs$ = this.subheader.breadCrumbsSubject.asObservable();
    this.description$ = this.subheader.descriptionSubject.asObservable();
    this.subheaderCSSClasses = this.layout.getStringCSSClasses('subheader');
    this.subheaderContainerCSSClasses = this.layout.getStringCSSClasses(
      'subheader_container'
    );
    this.subheaderMobileToggle = this.layout.getProp('subheader.mobileToggle');
    this.subheaderDisplayDesc = this.layout.getProp('subheader.displayDesc');
    this.subheaderDisplayDaterangepicker = this.layout.getProp(
      'subheader.displayDaterangepicker'
    );
    this.breadcrumbs$.subscribe((res) => {
      this.breadcrumbs = res;
      this.cdr.detectChanges();
    });
  }
  ngAfterViewInit() {
    KTUtil.ready(() => {
      KTLayoutSubheader.init('kt_subheader');
    });
  }
}
