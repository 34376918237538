import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {DateFormatPipe} from './pipe/format-date.pipe';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import {NotificationService} from '../service/notification.service';
import {I18nModule} from '../i18n/i18n.module';
import { SelectLanguageComponent } from './component/select-language/select-language.component';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzResultModule } from 'ng-zorro-antd/result';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import {CurrencyFormatPipe} from './pipe/currency-format.pipe';
import {ImageFormatPipe} from './pipe/image-format.pipe';
import {QRCodeModule} from 'angularx-qrcode';
import {OrderStatusFormatPipe} from './pipe/order-status-format.pipe';
import {FullNameFormatPipe} from './pipe/fullname-format.pipe';
import {NzPaginationModule} from 'ng-zorro-antd/pagination';
import {NzInputModule} from 'ng-zorro-antd/input';
import {NzButtonModule} from 'ng-zorro-antd/button';
import {NzSelectModule} from 'ng-zorro-antd/select';
import {NzModalModule} from 'ng-zorro-antd/modal';
import {NzIconModule} from 'ng-zorro-antd/icon';
import {NzLayoutModule} from 'ng-zorro-antd/layout';
import {NzCollapseModule} from 'ng-zorro-antd/collapse';
import {NzTableModule} from 'ng-zorro-antd/table';
import {InlineSVGModule} from 'ng-inline-svg';
import {FirstLetterPipe} from './pipe/first-letter.pipe';
import {NzInputNumberModule} from 'ng-zorro-antd/input-number';
import {NzUploadModule} from 'ng-zorro-antd/upload';
import {MatTabsModule} from '@angular/material/tabs';
import {NzTabsModule} from 'ng-zorro-antd/tabs';
import { PhoneInputComponent } from './controls/phone-input/phone-input.component';
import { ProvinceSelectComponent } from './controls/province-select/province-select.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import { CountrySelectComponent } from './controls/country-select/country-select.component';
import {NzCheckboxModule} from 'ng-zorro-antd/checkbox';
import {NzAutocompleteModule} from 'ng-zorro-antd/auto-complete';
import {NgxBarcodeModule} from 'ngx-barcode';
import {NzRadioModule} from 'ng-zorro-antd/radio';
import {NzSwitchModule} from 'ng-zorro-antd/switch';
import {NzCardModule} from 'ng-zorro-antd/card';
import {NzPopoverModule} from 'ng-zorro-antd/popover';
import {NzToolTipModule} from 'ng-zorro-antd/tooltip';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NzTableModule,
    NzPaginationModule,
    NzInputModule,
    NzButtonModule,
    NzSelectModule,
    NzModalModule,
    NzIconModule,
    NzLayoutModule,
    ScrollingModule,
    DragDropModule,
    NzCollapseModule,
    NzNotificationModule,
    I18nModule,
    NzFormModule,
    NzResultModule,
    NzTagModule,
    NzDropDownModule,
    NzDatePickerModule,
    ScrollingModule,
    DragDropModule,
    QRCodeModule,
    InlineSVGModule,
    NzInputNumberModule,
    NzUploadModule,
    MatTabsModule,
    NzTabsModule,
    MatCheckboxModule,
    PerfectScrollbarModule,
    NzCheckboxModule,
    NzAutocompleteModule,
    NgxBarcodeModule,
    NzRadioModule,
    NzSwitchModule,
    NzCardModule,
    NzPopoverModule,
    NzToolTipModule
  ],
  exports: [
    DateFormatPipe,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NzTableModule,
    NzPaginationModule,
    NzInputModule,
    NzButtonModule,
    NzSelectModule,
    NzModalModule,
    NzIconModule,
    NzCollapseModule,
    NzNotificationModule,
    I18nModule,
    SelectLanguageComponent,
    NzFormModule,
    NzResultModule,
    NzTagModule,
    NzDropDownModule,
    NzDatePickerModule,
    ScrollingModule,
    DragDropModule,
    CurrencyFormatPipe,
    ImageFormatPipe,
    FullNameFormatPipe,
    OrderStatusFormatPipe,
    QRCodeModule,
    InlineSVGModule,
    FirstLetterPipe,
    NzInputNumberModule,
    NzUploadModule,
    MatTabsModule,
    NzTabsModule,
    PhoneInputComponent,
    ProvinceSelectComponent,
    CountrySelectComponent,
    MatCheckboxModule,
    PerfectScrollbarModule,
    NzCheckboxModule,
    NzAutocompleteModule,
    NgxBarcodeModule,
    NzRadioModule,
    NzSwitchModule,
    NzCardModule,
    NzPopoverModule,
    NzToolTipModule
  ],
  declarations: [
    DateFormatPipe,
    SelectLanguageComponent,
    CurrencyFormatPipe,
    ImageFormatPipe,
    FullNameFormatPipe,
    OrderStatusFormatPipe,
    FirstLetterPipe,
    PhoneInputComponent,
    ProvinceSelectComponent,
    CountrySelectComponent,
  ],
  providers: [NotificationService],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class SharedModule { }
