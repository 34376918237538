import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from '../../../service/user-service';

@Component({
  selector: 'app-success-register',
  templateUrl: './success-register.component.html',
  styleUrls: ['./success-register.component.scss']
})
export class SuccessRegisterComponent implements OnInit {
  name: any;
  email: any;
  constructor( private router: Router,
               private route: ActivatedRoute,
               private portalService: UserService) { }

  ngOnInit(): void {
    const name = this.route.snapshot.paramMap.get('name');
    const email = this.route.snapshot.paramMap.get('email');
    this.name = name;
    this.email = email;
  }

}
