<!--begin::Signin-->
<div class="login-form login-signin">
  <!--begin::Form-->
  <form
    class="form"
    [formGroup]="validateForm"
    novalidate="novalidate"
    id="kt_login_signin_form"
    (ngSubmit)="submitForm()"
  >
    <!--begin::Title-->
    <div class="pb-13 pt-lg-0 pt-5">
      <h3 class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg">
       HID
      </h3>
      <span class="text-muted font-weight-bold font-size-h4"
      >New Here?
        <a
          routerLink="/auth/registration"
          id="kt_login_signup"
          class="text-primary font-weight-bolder"
        >{{ "SIGNUP" | translate }}</a
        >
      </span>
    </div>
    <!--end::Title-->

    <!-- begin::Alert info-->
    <!--<ng-container *ngIf="!hasError">
      <div class="mb-10 alert alert-custom alert-light-info alert-dismissible">
        <div class="alert-text">
          Use account <strong>{{ defaultAuth.email }}</strong> and password
          <strong>{{ defaultAuth.password }}</strong> to continue.
        </div>
      </div>
    </ng-container>-->
    <!-- end::Alert info-->

    <!-- begin::Alert error-->
    <ng-container *ngIf="messageError">
      <div
        class="mb-10 alert alert-custom alert-light-danger alert-dismissible"
      >
        <div class="alert-text">{{messageError}}</div>
      </div>
    </ng-container>
    <!-- end::Alert error-->

    <!--begin::Form group-->
    <div class="form-group">
      <label class="font-size-h6 font-weight-bolder text-dark">Username</label>
      <input
        class="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
        type="email"
        name="email"
        formControlName="username"
        autocomplete="off"
        [ngClass]="{ 'is-invalid': validateForm.controls['username'].invalid }"
      />
      <ng-container
        [ngTemplateOutlet]="formError"
        [ngTemplateOutletContext]="{
          validation: 'required',
          message: 'Username is required',
          control: validateForm.controls['username']
        }"
      ></ng-container>
      <ng-container
        [ngTemplateOutlet]="formError"
        [ngTemplateOutletContext]="{
          validation: 'username',
          message: 'Username is invalid',
          control: validateForm.controls['username']
        }"
      ></ng-container>
      <ng-container
        [ngTemplateOutlet]="formError"
        [ngTemplateOutletContext]="{
          validation: 'minLength',
          message: 'Username should have at least 3 symbols',
          control: validateForm.controls['username']
        }"
      ></ng-container>
      <ng-container
        [ngTemplateOutlet]="formError"
        [ngTemplateOutletContext]="{
          validation: 'maxLength',
          message: 'Username should have maximum 360 symbols',
          control: validateForm.controls['username']
        }"
      ></ng-container>
    </div>
    <!--end::Form group-->

    <!--begin::Form group-->
    <div class="form-group">
      <div class="d-flex justify-content-between mt-n5">
        <label class="font-size-h6 font-weight-bolder text-dark pt-5"
        >Password</label
        >
        <a
          tabindex="-1"
          routerLink="/auth/forgot-password"
          class="text-primary font-size-h6 font-weight-bolder text-hover-primary pt-5"
          id="kt_login_forgot"
        >
          Forgot Password ?
        </a>
      </div>

      <input
        class="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
        type="password"
        name="password"
        autocomplete="off"
        formControlName="password"
        [ngClass]="{ 'is-invalid': validateForm.controls['password'].invalid }"
      />
      <ng-container
        [ngTemplateOutlet]="formError"
        [ngTemplateOutletContext]="{
          validation: 'required',
          message: 'Password is required',
          control: validateForm.controls['password']
        }"
      ></ng-container>
      <ng-container
        [ngTemplateOutlet]="formError"
        [ngTemplateOutletContext]="{
          validation: 'minlength',
          message: 'Password should have at least 3 symbols',
          control: validateForm.controls['password']
        }"
      ></ng-container>
      <ng-container
        [ngTemplateOutlet]="formError"
        [ngTemplateOutletContext]="{
          validation: 'maxLength',
          message: 'Password should have maximum 100 symbols',
          control: validateForm.controls['password']
        }"
      ></ng-container>
    </div>
    <!--end::Form group-->

    <!--begin::Action-->
    <div class="pb-lg-0 pb-5">
      <button
        type="submit"
        id="kt_login_signin_submit"
        class="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-3"
        [disabled]="validateForm.invalid"
      >
        Sign In
      </button>
      <!--<ng-container *ngIf="isLoading$ | async">
        <span class="spinner spinner-primary ml-5"></span>
      </ng-container>-->
    </div>
    <!--end::Action-->
  </form>
  <!--end::Form-->
</div>
<!--end::Signin-->

<ng-template
    #formError
    let-control="control"
    let-message="message"
    let-validation="validation"
>
  <ng-container
    *ngIf="control.hasError(validation) && (control.dirty || control.touched)"
  >
    <div class="fv-plugins-message-container">
      <div class="fv-help-block">
        {{ message }}
      </div>
    </div>
  </ng-container>
</ng-template>



