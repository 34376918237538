import { BrowserModule } from '@angular/platform-browser';
import {APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {LayoutModule} from './component/admin-layout/layout.module';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {ReactiveFormsModule} from '@angular/forms';
import {SharedModule} from './shared/shared.module';
import {LoaderService} from './service/loader.service';
import {AppConfigService} from '../app-config.service';
import {AuthModule} from './component/auth/auth.module';
import {EffectsModule} from '@ngrx/effects';
import {StoreModule} from '@ngrx/store';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {ErrorInterceptor} from './shared/interceptor/error.interceptor';
import {AuthGuard} from './shared/guards/guards.class';
import {PageDefaultModule} from './shared/component/page-default/page-default.module';
import {en_US, NZ_DATE_LOCALE, NZ_I18N, vi_VN} from 'ng-zorro-antd/i18n';
import {appInitializer} from './shared/helpers/app.initializer';
import {AccountService} from './service/account-service';
import {ControlModule} from './component/admin-layout/controls/control.module';
import {FacebookLoginProvider, GoogleLoginProvider, SocialAuthServiceConfig, SocialLoginModule} from 'angularx-social-login';
import { NgbDateCustomParserFormatter } from "./shared/utils/date-formatter";
import { NgbDateParserFormatter, NgbDateAdapter } from "@ng-bootstrap/ng-bootstrap";
import { NgbDateCustomDateAdapter } from "./shared/utils/date-adapter";
import {AngularFireDatabaseModule} from '@angular/fire/database';
import {AngularFireAuthModule} from '@angular/fire/auth';
import {AngularFireMessagingModule} from '@angular/fire/messaging';
import {AngularFireModule} from '@angular/fire';
import {environment} from '../environments/environment';
import {MessagingService} from './service/messaging.service';
import {AsyncPipe} from '@angular/common';
import {vi, enUS} from 'date-fns/locale';

export function configServiceFactory(config: AppConfigService) {
  return () => config.load();
}
@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    LayoutModule,
    HttpClientModule,
    ReactiveFormsModule,
    SharedModule,
    AuthModule,
    PageDefaultModule,
    EffectsModule.forRoot([]),
    StoreModule.forRoot({}),
    StoreDevtoolsModule.instrument(),
    ControlModule,
    SocialLoginModule,
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.firebase),
  ],
  exports: [ ],
  providers: [
    MessagingService, AsyncPipe,
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              '311289615953-kec0p1kcsj2fs60b5c5vgv9umla8baa8.apps.googleusercontent.com'
            )
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider('1101023373682553')
          }
        ]
      } as SocialAuthServiceConfig,
    },
    { provide: APP_INITIALIZER, useFactory: appInitializer, multi: true, deps: [AccountService] },
    LoaderService,
    AppConfigService,
    { provide: APP_INITIALIZER, useFactory: configServiceFactory, deps: [AppConfigService], multi: true},
    { provide: NZ_I18N, useValue: vi_VN },
    {provide: NZ_DATE_LOCALE, useValue: vi},
    {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
    AuthGuard,
    {provide: NgbDateAdapter, useClass: NgbDateCustomDateAdapter},               //le.huathi - custom date adapter
    { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter } //le.huathi - custom date formatter
  ],

  bootstrap: [AppComponent],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class AppModule { }
