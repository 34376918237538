<div class="d-flex flex-column flex-md-row align-items-center justify-content-between container-fluid">
  <div class="nav nav-dark">
  <a
    href="/about"
    target="_blank"
    class="nav-link pl-0 pr-5"
  >About</a
  >
  <a
    href="/team"
    target="_blank"
    class="nav-link pl-0 pr-5"
  >Team</a
  >
  <a
    href="/contact"
    target="_blank"
    class="nav-link pl-0 pr-0"
  >Contact</a
  >
</div>
</div>
